import React, { useState, useEffect } from "react";
import contact from "../Assets/Images/contact.jpg";
import { Link, useNavigate } from "react-router-dom";
import { FaHeadphones } from "react-icons/fa";
import { SiSpringsecurity } from "react-icons/si";
import { LuMic2 } from "react-icons/lu";
import { FaUserTie } from "react-icons/fa6";
import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import { ImLocation2 } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import app from "../Assets/Images/mobile.jpg";
import adjock from "../Assets/Images/Adjock.png";
import "./Contact.css";

import axios from "axios";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
function Contact() {

    const [showLink, setShowLink] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setShowLink(!isScrolled);
        };
    }, []);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [date, setDate] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = () => {
        const formData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            message: message,
        };
        postDataToBackend(formData);
    };

    const postDataToBackend = (formData) => {
        debugger;
        axios
            .post("http://127.0.0.1:8000/enquiry/", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("Enquiry sent successfully:", response.data);
                toast("Sent Successfully!");
            })
            .catch((error) => {
                if (error.response) {
                    console.error(
                        "Error response from backend server:",
                        error.response.data
                    );
                } else if (error.request) {
                    console.error("No response from server:", error.request);
                } else {
                    console.error("Error:", error.message);
                }
            });
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleShow1 = (e) => {
        setShow1(true);
    };

    const history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='container-fluid'>
            {showLink && (
                <div className="download-link" onClick={handleShow1}>
                    <Link className="link">Download App</Link>
                </div>
            )}
            <div className="banner5">
                <img src={contact} width="100%" className="image" />
            </div>
            <Dashboard />
            <div className="address ">
                <div className="row pb-4 " >
                    <h4
                        className="text-center my-5 fw-bold"
                    >
                        Get In Touch
                    </h4>
                    <div className="col-md container ">
                        <div className="row offset-1">
                            <div className="col-1">
                                <ImLocation2 size={40} className="" />
                            </div>
                            <div className="col-9 ms-3 ">
                                <h5 className="fw-bold">Registered Office:</h5>
                                <p>
                                    TARK INNOVATIONS PRIVATE LIMITED <br />
                                    #772/AAND 804, 3<sup>rd</sup> floor CK Complex , Vijayanagar
                                    3<sup>rd</sup> Stage ,Devaraja Mohalla, Mysuru
                                    Karnataka-570017
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md mb-3">
                        <div className="row  offset-1">
                            <div className="col-1 ">
                                <MdEmail size={40} />
                            </div>
                            <div className="col-8 ms-3">
                                <h5 className="fw-bold">Email Us</h5>
                                <Link style={{ textDecoration: "none", fontSize: "20px" }}>
                                    info@adjock.com
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md mb-3">
                        <div className="row  offset-1">
                            <div className="col-1">
                                <MdOutlinePhoneInTalk size={40} />
                            </div>
                            <div className="col-8 ms-3 fs-5">
                                <h5 className="fw-bold">Call Us</h5>
                                <p style={{ cursor: "pointer" }}>+91 8762121937</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact ">
                    <div className="row mx-5">
                        <div className="col-md  mt-2">
                            <div className="card p-2 shadow">
                                <div className="card-header text-center">
                                    <h3 className="fw-bold">GET IN TOUCH WITH OUR SUPPORT TEAM</h3>
                                </div>
                                <div className="card-body">
                                    <div className="my-2">
                                        <label className="form-label">FIRST NAME</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter first name"
                                            style={{ border: "1px solid" }}
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <label className="form-label">LAST NAME</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter last name"
                                            style={{ border: "1px solid" }}
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <label className="form-label">EMAIL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter email"
                                            style={{ border: "1px solid" }}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <label className="form-label">MOBILE NUMBER</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter mobile number"
                                            style={{ border: "1px solid" }}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <label className="form-label">MESSAGE</label>
                                        <textarea
                                            className="form-control"
                                            placeholder="Type here...................."
                                            style={{ border: "1px solid", height: "100px" }}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        className="w-100 btn btn-danger"
                                        onClick={handleSubmit}
                                    >
                                        SEND
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md mt-2">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center my-2 ">
                                        REACH THE APPROPRIATE TEAM
                                    </h3>
                                </div>
                                <div className="card-body mt-3">
                                    <div className="row shadow my-4 px-4">
                                        <div className="col-3 ">
                                            <FaHeadphones size={60} />
                                        </div>
                                        <div className="col">
                                            <h5 className="fw-bold">CUSTOMER SUPPORT</h5>
                                            <p>
                                                For support with your Transactions and other queries,
                                                <Link>click here</Link> or visit the Support section
                                                in the ADJOCK app.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row shadow my-4 px-4">
                                        <div className="col-3">
                                            <SiSpringsecurity size={60} />
                                        </div>
                                        <div className="col">
                                            <h5 className="fw-bold">CUSTOMER SECURITY</h5>
                                            <p>
                                                If you have security concerns, please report your
                                                issues with ADJOCK at <Link>security@adjock.com</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row shadow my-4 px-4 pb-3">
                                        <div className="col-3">
                                            <LuMic2 size={60} />
                                        </div>
                                        <div className="col">
                                            <h5 className="fw-bold">MEDIA RELATED QUERIES</h5>
                                            For press and media related queries please reach out to
                                            <Link> media@adjock.com.</Link>
                                        </div>
                                    </div>
                                    <div className="row shadow my-4 px-4 pb-3">
                                        <div className="col-3 ">
                                            <FaUserTie size={60} />
                                        </div>
                                        <div className="col">
                                            <h5 className="fw-bold">BUSINESS SUPPORT</h5>
                                            If you are a Business holder and want to attach your
                                            business with ADJOCK, <Link>click here</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: 'center',
                        justifyItems:'center',
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
            <Footer/>
           
        </div>
    )
}

export default Contact