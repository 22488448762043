import React, { useState, useEffect } from "react";
import contact from "../Assets/Images/service.jpg";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Service.css"

import { PiTelevisionSimpleFill } from "react-icons/pi";
import { MdOutlineCorporateFare } from "react-icons/md";
import { IoIosVideocam } from "react-icons/io";
import { MdSwitchVideo } from "react-icons/md";
import { FaCamera } from "react-icons/fa";
import { SiAdobephotoshop } from "react-icons/si";
import { SiBrandfolder } from "react-icons/si";
import { TbBrandAirtable } from "react-icons/tb";
import { FcAdvertising } from "react-icons/fc";
import { SiMicrostation } from "react-icons/si";
import { RiPrinterFill } from "react-icons/ri";
import { SiMinutemailer } from "react-icons/si";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { BsBackpack4Fill } from "react-icons/bs";
import { GiProgression } from "react-icons/gi";
import { IoBookSharp } from "react-icons/io5";
import { HiShoppingCart } from "react-icons/hi";
import { TiInfoLarge } from "react-icons/ti";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Dashboard";

import adjock from "../Assets/Images/Adjock.png";

import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import app from "../Assets/Images/mobile.jpg";
import { MdCampaign } from "react-icons/md";
import { MdDeveloperMode } from "react-icons/md";
import { FcConferenceCall } from "react-icons/fc";
import Footer from "./Footer";

function Service() {
    const [showLink, setShowLink] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setShowLink(!isScrolled);
        };
    }, []);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleShow1 = (e) => {
        setShow1(true);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [serviceName, setServiceName] = useState("");

    const handleShow = (e) => {
        setShow(true);
        const service = e.currentTarget.querySelector("h4").textContent;
        setServiceName(service);
    };


    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = () => {
        const formData = {
            name: name,
            email: email,
            phoneNumber: phoneNumber,
            message: message,
            service: serviceName,
        };
        postDataToApi(formData);
    };

    const postDataToApi = (formData) => {
        debugger;
        axios
            .post("http://127.0.0.1:8000/service/", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("Service request sent successfully:", response.data);
                toast("Sent Successfully!");
            })
            .catch((error) => {
                if (error.response) {
                    console.error(
                        "Error response from backend server:",
                        error.response.data
                    );
                } else if (error.request) {
                    console.error("No response from server:", error.request);
                } else {
                    console.error("Error:", error.message);
                }
            });
    };

    const history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='container-fluid'>
            {showLink && (
                <div className="download-link" onClick={handleShow1}>
                    <Link className="link" download>
                        Download App
                    </Link>
                </div>
            )}

            <div className="banner4">
                <img src={contact} width="100%" className="image" />
            </div>
            <Dashboard />
            <div className=" container-fluid">
                <h3 className="fw-bold text-center mt-3">Our Services</h3>
                <div className="row text-center my-2">
                    <div
                        className="col-md service  shadow p-3"
                        onClick={(e) => handleShow(e)}
                    >
                        <PiTelevisionSimpleFill className="icon " size={65} />
                        <h4 className="my-3 fw-bold">Televsion Commercials</h4>
                        <p>
                            Adjock specializes in crafting compelling television commercials,
                            strategically aired during program breaks or program transitions.
                            These advertisements, ranging from 30 seconds to one minute,
                            effectively showcase and promote products or services to
                            captivate the audience.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <MdOutlineCorporateFare className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Corporate Films</h4>
                        <p>
                            Specializing in the production of corporate films, Adjock delivers
                            tailored, non-advertisement based video content for businesses,
                            companies, corporations, or organizations. Our expertise ensures
                            the creation of engaging and purposeful films aligned with your
                            corporate goals and messaging.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <IoIosVideocam className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Video Viral</h4>
                        <p>
                            Adjock is your go-to for crafting viral videos that gain
                            popularity through the Internet's viral sharing process.
                            Leveraging platforms like YouTube, social media, and email, we
                            specialize in creating content that captivates audiences and
                            spreads organically, making your brand or message
                            widely recognized.
                        </p>
                    </div>
                </div>

                <div className="row text-center my-3">
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <MdSwitchVideo className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Videography</h4>
                        <p>
                            Adjock specializes in videography, seamlessly capturing dynamic
                            moving images on electronic and streaming media. Our services
                            encompass the entire spectrum of video production and
                            post-production, ensuring a comprehensive approach to bring your
                            vision to life with precision and creativity.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <FaCamera className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Photography</h4>
                        <p>
                            Adjock excels in the art and practice of photography, skillfully
                            capturing images by recording light through advanced methods.
                            Whether electronically using cutting-edge image sensors or through
                            traditional chemical processes with light-sensitive materials like
                            photographic film, we bring a blend of creativity and expertise to
                            visually represent your vision.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <SiAdobephotoshop className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Product Shoot</h4>
                        <p>
                            Adjock specializes in product photography, skillfully capturing
                            images to showcase, promote, and highlight the unique features of
                            your products. Our expertise extends to e-commerce platforms and
                            social media, ensuring visually compelling representations that
                            elevate your brand presence and drive engagement.
                        </p>
                    </div>
                </div>

                <div className="row text-center my-3">
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <SiBrandfolder className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Brand Management</h4>
                        <p>
                            Adjock's brand management approach with a thorough analysis of the
                            current market perception of brand. We plan and strategize to
                            shape the desired perception with your objectives. Our ongoing
                            efforts ensure that your brand is consistently perceived as
                            planned, securing and advancing toward its objectives.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <TbBrandAirtable className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Brand Development</h4>
                        <p>
                            In brand development, Adjock stands as a dedicated partner,
                            committed to maintaining quality, marketing assets, and building
                            unwavering consumer trust. Our approach revolves around an ongoing
                            process, strategically serving consumers and ensuring a dynamic
                            evolution in your brand presence.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <FcAdvertising className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Outdoor Advertising</h4>
                        <p>
                            Outdoor advertising, by Adjock, is a wide
                            array of advertisements displayed in public areas—be it on
                            roadside billboards, bus stops, train stations, or other public
                            spaces. Our expertise extends to banners and signs strategically
                            placed in areas like buildings, parks, and beaches, ensuring a
                            compelling and impactful outdoor presence for your brand.
                        </p>
                    </div>
                </div>

                <div className="row text-center my-3">
                    <div
                        className="col-md service p-3 shadow  mb-4"
                        onClick={(e) => handleShow(e)}
                    >
                        <SiMicrostation className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Microsites & Blogs</h4>
                        <p>
                            A microsite to promote individual products, events, or campaigns.
                            Simultaneously, we understand the essence of blogs—a form of
                            informational websites characterized by discrete, diary-style text
                            entries. Our approach seamlessly integrates these elements to
                            enhance your online presence and storytelling.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow  mb-4"
                        onClick={(e) => handleShow(e)}
                    >
                        <RiPrinterFill className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Print Advertising</h4>
                        <p>
                            Print advertising, masterfully executed by Adjock, involves the
                            strategic placement of impactful advertisements in hard copy
                            publications—newspapers, magazines, and journals. This approach
                            guarantees effective communication with your target audience
                            through carefully chosen print mediums.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow  mb-4"
                        onClick={(e) => handleShow(e)}
                    >
                        <SiMinutemailer className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Direct Mailers</h4>
                        <p>
                            In the domain of advertising, Adjock specializes in direct mail,
                            also known as admail or mailshot—a precise delivery of advertising
                            material to recipients of postal mail. Our approach ensures
                            targeted and impactful communication through strategic letterbox
                            drops, creating a meaningful connection with your audience.
                        </p>
                    </div>
                </div>

                <div className="row text-center my-3">
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <BsBackpack4Fill className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Packaging Designs</h4>
                        <p>
                            Our approach encompasses containment, protection, preservation,
                            transportation, and extends further to effectively inform and
                            sell. Adjock's commitment ensures your products are not only
                            packaged but presented with strategic and impactful design
                            throughout their journey from production to end use.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <MdOutlineQrCodeScanner className="icon " size={50} />
                        <h4 className="my-3 fw-bold">Point of Sale</h4>
                        <p>
                            Adjock takes the lead, orchestrating a seamless retail transaction
                            experience. From calculating the customer's amount owed to
                            presenting payment options, our comprehensive approach extends to
                            preparing invoices, ensuring a streamlined process that enhances
                            efficiency for both merchants and customers.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <GiProgression className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Promotions</h4>
                        <p>
                            An intricate practice of communicating persuasively to inform
                            target audiences about the merits of a product, service, brand, or
                            issue. Our approach transcends basic awareness, aiming to carve a
                            distinctive and enduring place in the minds of customers.
                        </p>
                    </div>
                </div>

                <div className="row text-center my-3">
                    <div
                        className="col-md service p-3 shadow "
                        onClick={(e) => handleShow(e)}
                    >
                        <TiInfoLarge className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Logo Designing</h4>
                        <p>
                            A logo is not just a symbol but a distinctive design crafted to
                            uniquely identify a company or organization, along with its
                            products, services, and employees. More than identification, a
                            logo is the embodiment of recognition and remembrance, serving as
                            the enduring face of your business.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <HiShoppingCart className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Merchandising</h4>
                        <p>
                            Adjock specializes in merchandising—an artful strategy
                            encompassing the presentation and sale of products to customers.
                            Be it in the digital sphere or within physical stores, our
                            proficiency in merchandising is wielded to shape customer intent,
                            ensuring retailers not only meet but surpass their
                            sales objectives.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <IoBookSharp className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Book Publishing</h4>
                        <p>
                            Publishing a comprehensive endeavor that involves making
                            information, literature, music, software, and diverse content
                            available to the public, both for purchase and without cost.
                            Traditionally associated with the generation and distribution of
                            printed works like books, comic books, newspapers, and magazines,
                        </p>
                    </div>
                </div>

                <div className="row text-center my-3">
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <FcConferenceCall className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Event Design Conferences</h4>
                        <p>
                            Adjock aims to not only captivate your guests' attention but also
                            elevate the ambiance, resulting in a truly memorable experience.
                            It seamlessly weaves together various elements, including visuals,
                            audio, and overall experience, to create a harmonious and
                            impactful event.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <MdDeveloperMode className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Web Design & Development</h4>
                        <p>
                            Web design and development is an term that describes the process
                            of creating a website. Like the name suggests, Web design
                            determines the look and feel of a website, while web development
                            determines how it functions.
                        </p>
                    </div>
                    <div
                        className="col-md service p-3 shadow"
                        onClick={(e) => handleShow(e)}
                    >
                        <MdCampaign className="icon " size={60} />
                        <h4 className="my-3 fw-bold">Digital Campaigns</h4>
                        <p>
                            It involves the Internet and various online digital technologies,
                            encompassing desktop computers, mobile phones, and other digital
                            media platforms. Our proficiency in this to ensures effective
                            promotion and visibility for your products and services .
                        </p>
                    </div>
                </div>

                <div className="modal">
                    <Modal show={show} onHide={handleClose} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <img src={adjock} width={100} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card">
                                <div className="card-header text-center">
                                    <h3 style={{ color: "#1472EB" }}>{serviceName}</h3>
                                </div>
                                <div className="card-body">
                                    <p className="fw-bold">
                                        Please fill the below form {serviceName} inquiry team will
                                        reach you
                                    </p>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Your Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel
                                        controlId="floatingPhone"
                                        label="Phone Number"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </FloatingLabel>
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            id="floatingInputCustom"
                                            type="email"
                                            placeholder="name@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <label htmlFor="floatingInputCustom">Email address</label>
                                    </Form.Floating>
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            id="floatingInputCustom"
                                            type="text"
                                            as="textarea"
                                            placeholder="name@example.com"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                        <label htmlFor="floatingInputCustom">Message</label>
                                    </Form.Floating>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={handleSubmit}
                                className="w-100"
                            >
                                Send
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: 'center',
                        justifyItems:'center',
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
            </div>
            <Footer />
        </div>
    )
}

export default Service