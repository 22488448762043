import React,{useEffect} from 'react'
import Accordion from "react-bootstrap/Accordion";
import Dashboard from './Dashboard';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
function Terms() {
    const history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='container-fluid'>
        <Dashboard/>
          <div className="container col-md-8 p-5">
          <h2 className="text-center mt-5 fw-bold" style={{ color: "#2361B6" }}>
            Terms and Conditions
          </h2>
          <p className="text-center mt-5 fw-bold">
            Explore comprehensive details about ADJOCK's legal Terms and
            Conditions by reading through the dedicated paragraph listed below
          </p>
          <Accordion style={{ textAlign: "justify" }} className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>COPYRIGHTS</Accordion.Header>
              <Accordion.Body>
                <p>
                  This website and its content are owned by Tark Innovations pvt
                  ltd. and are protected by copyright laws and international
                  treaty provisions. Reproduction of any material on this site
                  is only authorized for exclusive information for strictly
                  personal and private use. Any reproduction and/or
                  representation of all or part of the site, or any of the
                  elements that compose it (such as brands, logos, photographs,
                  images, illustrations, audio/videos files ...), on any media
                  whatsoever, for any other purpose, including commercial, is
                  expressly prohibited, unless the prior written authorization
                  from Tark Innovations pvt ltd .
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>TRADEMARKS</Accordion.Header>
              <Accordion.Body>
                <p>
                  All company names, trademarks, services marks, trade names,
                  logos, and icons are protected by trademark law. Nothing
                  contained on this website should be construed as granting any
                  license or right to use any trademark displayed on this
                  website without the written permission of Tark Innovations pvt
                  ltd or such third party that may own said trademarks. Linking
                  to www.adjock.com <br /> The Tark Innovations pvt ltd website
                  authorizes hypertext links pointing to its content, as long as
                  such links adhere to Tark Innovations pvt ltd linking policy,
                  as follows:
                  <br /> (i) when selected by a user, the link must display the
                  website on a new full-screen window and not within a frame on
                  the linking website (no deep-linking); <br />
                  (ii) any link to the website must be a text only link clearly
                  marked " Tark Innovations pvt ltd. website" or ; and
                  <br /> (iii) the appearance, position and other aspects of the
                  link may not be such as to damage or dilute the goodwill
                  associated with Tark Innovations pvt ltd.'s names and
                  trademarks. In particular no website containing controversial,
                  pornographic or racist content will be authorized to link to
                  the Tark Innovations pvt ltd website. <br />
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>IP ADDRESSES AND COOKIES</Accordion.Header>
              <Accordion.Body>
                <p>
                  We may collect information about your computer, including
                  where available your IP address, operating system and browser
                  type, for system administration and to report aggregate
                  information to our advertisers. This is statistical data about
                  our users' browsing actions and patterns, and does not
                  identify any individual.
                  <br /> For the same reason, we may obtain information about
                  your general internet usage by using a cookie file which is
                  stored on the hard drive of your computer. Cookies contain
                  information that is transferred to your computer's hard drive.
                  They help us to improve our site and to deliver a better and
                  more personalised service.
                </p>
                <h5>They enable us:</h5>
                <li> To estimate our audience size and usage pattern.</li>
                <li>
                  To store information about your preferences, and so allow us
                  to customise our site according to your individual interests.
                </li>
                <li> To speed up your searches.</li>
                <li> To recognise you when you return to our site.</li>
                You may refuse to accept cookies by activating the setting on
                your browser which allows you to refuse the setting of cookies.
                However, if you select this setting you may be unable to access
                certain parts of our site. Unless you have adjusted your browser
                setting so that it will refuse cookies, our system will issue
                cookies when you log on to our site.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>GOOGLE ANALYTICS</Accordion.Header>
              <Accordion.Body>
                <p>
                  This website uses Google Analytics, a web analytics service
                  provided by Google, Inc. (“Google”). Google Analytics uses
                  “cookies”, which are text files placed on your computer, to
                  help the website analyze how users use the site. The
                  information generated by the cookie about your use of the
                  website (including your IP address) will be transmitted to and
                  stored by Google on servers in the United States. Google will
                  use this information for the purpose of evaluating your use of
                  the website, compiling reports on website activity for website
                  operators and providing other services relating to website
                  activity and internet usage. Google may also transfer this
                  information to third parties where required to do so by law,
                  or where such third parties process the information on
                  Google's behalf. Google will not associate your IP address
                  with any other data held by Google. You may refuse the use of
                  cookies by selecting the appropriate settings on your browser,
                  however please note that if you do this you may not be able to
                  use the full functionality of this website. By using this
                  website, you consent to the processing of data about you by
                  Google in the manner and for the purposes set out above.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>DATA PRIVACY</Accordion.Header>
              <Accordion.Body>
                <p>
                  Personal data collected from users via online forms,
                  personalized services and other questionnaires are processed
                  using automated systems. All such personal data are collected
                  for purposes of managing users’ requests and compiling
                  statistics internally; they are kept entirely confidential and
                  are intended for the sole use of Tark Innovations pvt ltd
                  group employees and departments. Some information requested in
                  online forms must be provided for the forms to be processed.
                  If such information is wrong or incomplete, the data will not
                  be sent to Tark Innovations pvt ltd .
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>DISCLAMER OF LIABILITIES</Accordion.Header>
              <Accordion.Body>
                <p>
                  All content on this website is provided on an "as is", "as
                  available" basis without warranty of any kind either express
                  or implied, including but not limited to any implied
                  warranties of merchantability, fitness for a particular
                  purpose, accuracy and non-infringement. Tark Innovations pvt
                  ltd makes no warranty as to the accuracy, completeness,
                  currency or reliability of any content available through this
                  website. Users are responsible for verifying any information
                  before relying on it. Use of the website and the content
                  available on the website is at the user's sole risk.
                </p>
                <p>
                  Tark Innovations pvt ltd reserves the right to change, correct
                  or delete any content on this website. Tark Innovations pvt
                  ltd specifically disclaims any liability, whether based in
                  contract, tort, negligence, strict liability or otherwise, for
                  any direct, indirect, incidental, consequential or special
                  damages arising out of or in any way connected with access to
                  or use of the website Links on the www.adjock.com website may
                  take users to websites over which Tark Innovations pvt ltd
                  does not exercise any control. Tark Innovations pvt ltd
                  disclaims any liability for any damages caused by the content
                  of one or several sites linked to the Tark Innovations pvt ltd
                  website.
                </p>
                <p>
                  Users are responsible for taking all necessary precautions to
                  ensure that any content they may obtain from the website is
                  free of viruses. Tark Innovations pvt ltd makes no
                  representations or warranties that use of the website will be
                  uninterrupted or error-free and disclaims any liability for
                  problems encountered during connection to the website or for
                  any telecommunications failure.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <Footer/>
    </div>
  )
}

export default Terms