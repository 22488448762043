import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Dashboard from "./Components/Dashboard";
import Footer from "./Components/Footer";
import About from "./Components/About";
import News from "./Components/News";
import Career from "./Components/Career";
import Service from "./Components/Service";
import Contact from "./Components/Contact";
import AppStore from "./Components/AppStore";
import Faq from "./Components/Faq";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";


function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/career" element={<Career />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/appstore" element={<AppStore />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
