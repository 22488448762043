import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTypography,
} from "mdb-react-ui-kit";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import "./Faq.css"

function Faq() {
    const history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='container-fluid'>
            <Dashboard />
            <MDBContainer>
                <section>
                    <MDBTypography
                        tag="h3"
                        className="text-center  pb-2 text-primary fw-bold"
                        style={{ marginTop: '80px' }}
                    >
                        Frequently asked questions
                    </MDBTypography>

                    <p className="text-center mb-5">
                        Find the answers for the most frequently asked questions below
                    </p>

                    <MDBRow>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBTypography tag="h6" className="mb-3  fw-bold">
                                <MDBIcon far icon="paper-plane text-primary pe-2 " /> How to
                                create an business account?
                            </MDBTypography>
                            <p>
                                Absolutely! We work with top payment companies which guarantees
                                your safety and security. All billing information is stored on
                                our payment processing partner.
                            </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBTypography tag="h6" className="mb-3  fw-bold">
                                <MDBIcon fas icon="pen-alt text-primary pe-2" /> How can I know
                                the discounts from ADJOCK?
                            </MDBTypography>
                            <p>
                                Yes, it is possible! You can cancel your subscription anytime in
                                your account. Once the subscription is cancelled, you will not
                                be charged next month.
                            </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBTypography tag="h6" className="mb-3  fw-bold">
                                <MDBIcon fas icon="user text-primary pe-2" /> When will my
                                business become visible for users?
                            </MDBTypography>
                            <p>
                                Currently, we only offer monthly subscription. You can upgrade
                                or cancel your monthly account at any time with no further
                                obligation.
                            </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBTypography tag="h6" className="mb-3  fw-bold">
                                <MDBIcon fas icon="rocket text-primary pe-2" /> What all can I
                                do with ADJOCK?
                            </MDBTypography>
                            <p>
                                Yes. Go to the billing section of your dashboard and update your
                                payment information.
                            </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBTypography tag="h6" className="mb-3  fw-bold">
                                <MDBIcon fas icon="home text-primary pe-2" /> How to switch user
                                account to business account?
                            </MDBTypography>
                            <p>
                                Unfortunately no We do not issue full or partial refunds for any
                                reason.
                            </p>
                        </MDBCol>
                        <MDBCol md="6" lg="4" className="mb-4">
                            <MDBTypography tag="h6" className="mb-3 + fw-bold">
                                <MDBIcon fas icon="book-open text-primary pe-2" /> I have
                                created my account with wrong Email Id How to correct it?
                            </MDBTypography>
                            <p>
                                Of course! We’re happy to offer a free plan to anyone who wants
                                to try our service.
                            </p>
                        </MDBCol>
                    </MDBRow>
                </section>
            </MDBContainer>
            <Footer />
        </div>
    )
}

export default Faq