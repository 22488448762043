import React, { useState, useEffect } from "react";
import career from "../Assets/Images/job.jpg";
import job from "../Assets/Images/jobs.jpg";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Dashboard";
import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import adjock from "../Assets/Images/Adjock.png";
import design from "../Assets/Images/design.jpg";
import market from "../Assets/Images/market.jpg";
import hr from "../Assets/Images/hr.jpg";
import app from "../Assets/Images/mobile.jpg";
import adbg from "../Assets/Images/adbg.png";
import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";
import { Modal } from "react-bootstrap";
import "./Career.css"
import Footer from "./Footer";

function Career() {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [showLink, setShowLink] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setShowLink(!isScrolled);
    };
  }, []);

  const handleShow1 = (e) => {
    setShow1(true);
  };

  const notify = () => toast("Sent Successfully!");

  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid">
      {showLink && (
        <div className="download-link" onClick={handleShow1}>
          <Link className="link" download>
            Download App
          </Link>
        </div>
      )}
      <Dashboard />
      <div className="banner3 my-1">
        <img src={career} width="100%" />
      </div>
      <div className="contents3  pb-5">
        <div className="row">
          <div className="col-md-7 p-4">

            <h4 className="text-center mb-3 fw-bold">Life @  <Link to="/" className="ms-1">
              <img src={adbg} width={100} />
            </Link> </h4>
            <h4
              style={{ textAlign: "justify", fontStyle: "oblique" }}
              className="fw-bold"
            >
              <ImQuotesLeft className="me-2" />
              Adjock can provide you a career that can suit your own
              talents,qualification and inclination. Challenges come in the form
              of normal work or the opportunity to bring about economic changes
              in the country. <ImQuotesRight className="ms-2" />
            </h4>
            <div className="mt-3" style={{ textAlign: "justify" }}>
              <p>
                We are looking forward for independent, mature people willing to
                put in smart work. We will ensure that you acquire sufficient
                domain knowledge and be a positive contributor for the
                organisation and also curate self development.
                <br />
              </p>
            </div>
          </div>
          <div className="col-md p-4 text-center d-none d-sm-block">
            <img
              src={job}
              width="80%"
              height={250}
              style={{ borderRadius: "14px" }}
            />
          </div>
        </div>
        <div className=" text-center">
          <h2 className="my-3 fw-bold">Listed Jobs</h2>
          <div className="row">
            <div className="col-md">
              <div className="flipper-container">
                <div className="flip">
                  <div className="front face text-center">
                    <img src={design} height={300} />
                  </div>
                  <div class="back face">
                    <h3>Software Developer !!</h3>
                    <h5>Job Description</h5>
                    <p>
                      <span>Role:</span> Full Stack Developer
                    </p>
                    <p>
                      <span>No Vacancy:</span> 24
                    </p>
                    <p>
                      <span>Experience:</span> 0-2 year(s)
                    </p>
                    <p>
                      <span>Location:</span> PAN India
                    </p>
                    <p>
                      <span>Published On:</span> Jan 18, 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="flipper-container">
                <div className="flip">
                  <div className="front face text-center">
                    <img src={market} height={300} />
                  </div>
                  <div class="back face">
                    <h3>Marketing !!</h3>
                    <h5>Job Description</h5>
                    <p>
                      <span>Role:</span> Marketing Team
                    </p>
                    <p>
                      <span>No Vacancy:</span> 20
                    </p>
                    <p>
                      <span>Experience:</span> 0-2 year(s)
                    </p>
                    <p>
                      <span>Location:</span> PAN India
                    </p>
                    <p>
                      <span>Published On:</span> Jan 18, 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="flipper-container">
                <div className="flip">
                  <div className="front face text-center">
                    <img src={hr} height={300} />
                  </div>
                  <div class="back face">
                    <h3>Human Resource !!</h3>
                    <h5>Job Description</h5>
                    <p>
                      <span>Role:</span> HR Manager
                    </p>
                    <p>
                      <span>No Vacancy:</span> 4
                    </p>
                    <p>
                      <span>Experience:</span> 1-2 year(s)
                    </p>
                    <p>
                      <span>Location:</span> PAN India
                    </p>
                    <p>
                      <span>Published On:</span> Jan 18, 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: 'center',
                        justifyItems:'center',
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </div>
  )
}

export default Career