import React, { useState, useEffect } from "react";
import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { NavLink, Outlet } from "react-router-dom";
import adjock from "../Assets/Images/Adjock.png";

import "./Dashboard.css";

function Dashboard() {
  const [showSearch, setShowSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };

  const handleCloseClick = () => {
    setShowSearch(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const dashboard = document.querySelector(".dashboard");
      if (dashboard) {
        const scrollPosition = window.scrollY;
        const threshold = 50;

        if (scrollPosition > threshold) {
          dashboard.style.backgroundColor = "white";
        } else {
          dashboard.style.backgroundColor = "rgba(255,255,255,0.2)";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 996);
    };

    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="dashboard">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="py-3  me-2 "
          style={{
            backgroundColor: isMobile ? "white" : "rgba(255, 255, 255, 0.2)",
          }}
        >
          <Navbar.Brand>
            <Link to="/" className="ms-3">
              <img src={adjock} width={160} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
              {showSearch ? (
                <div className="search-bar ">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control py-2"
                      placeholder="Search..."
                      style={{ width: "600px" }}
                    />
                    <FaSearch className="search-icon" size={25} />
                  </div>
                  <div className="ms-4" onClick={handleCloseClick}>
                    <FaTimes size={25} style={{ cursor: "pointer" }} />
                  </div>
                </div>
              ) : (
                <>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/About"
                      className="nav-link pages"
                      activeClassName="active"
                    >
                      ABOUT US
                    </NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink
                      exact
                      to="/News"
                      className="nav-link pages"
                      activeClassName="active"
                    >
                      NEWS ROOM
                    </NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink
                      exact
                      to="/Career"
                      className="nav-link pages"
                      activeClassName="active"
                    >
                      CAREER
                    </NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink
                      exact
                      to="/service"
                      className="nav-link pages"
                      activeClassName="active"
                    >
                      SERVICES
                    </NavLink>
                  </li>
                  <li className="nav-item me-2">
                    <NavLink
                      exact
                      to="/Contact"
                      className="nav-link pages"
                      activeClassName="active"
                    >
                      CONTACT
                    </NavLink>
                  </li>
                </>
              )}
              <li className="nav-item me-3">
                <NavLink
                  exact
                  to="/AppStore"
                  className="nav-link pages"
                  activeClassName="active"
                >
                  DOWNLOAD APP
                  {/* <GrAndroid className="mx-2" size={23} />{" "}
                                    <FaApple className="" size={23} /> */}
                </NavLink>
              </li>
            </ul>
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
