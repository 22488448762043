import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import news from "../Assets/Images/news.jpg";
import { Link, useNavigate } from "react-router-dom";
import "./News.css";
import { Modal } from "react-bootstrap";
import app from "../Assets/Images/mobile.jpg";
import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaShare } from "react-icons/fa6";
import { FaCommentAlt } from "react-icons/fa";
import { PiUserCircleLight } from "react-icons/pi";
import { IoMdSend } from "react-icons/io";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import adjock from "../Assets/Images/Adjock.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import news1 from "../Assets/Images/ind1.jpg";
import news2 from "../Assets/Images/ind2.jpg";
import news3 from "../Assets/Images/ind3.jpg";
import Footer from "./Footer";

function News() {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const handleShow1 = (e) => {
    setShow1(true);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const handleShow2 = (e) => {
    setShow2(true);
  };

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);

  const handleShow3 = (e) => {
    setShow3(true);
  };

  const [comment, setComment] = useState("");
  const [showLink, setShowLink] = useState(true);
  const handleSubmit = () => {
    const formData = {
      comment: comment,
    };
    toast("Sent successfully");
  };

  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid">
      {showLink && (
        <div className="download-link" onClick={handleShow1}>
          <Link className="link" download>
            Download App
          </Link>
        </div>
      )}
      <Dashboard />
      <div className="banner2">
        <img src={news} width="100%" className="imag" />
      </div>
      <div className="container-fluid contents2">
        <h3 className="text-center fw-bold">Our Blog</h3>
        <h1 className="text-center my-4" style={{ fontFamily: "fantasy" }}>
          Latest Blog & News
        </h1>
        <div className="row text-center">
          <div className="col-md-4 news ">
            <img src={news1} width="100%" />
            <div className="desc my-4">
              <h5 className="fw-bold">On 01 Jan 2023</h5>
              <p>
                Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                interdum. Aliquam dolor eget urna ultricies tincidunt libero sit
                amet
              </p>
              <div
                className="w-100 d-flex p-1 views"
                style={{ backgroundColor: "blue" }}
              >
                <p
                  className="ms-3"
                  onClick={handleShow2}
                  style={{ cursor: "pointer" }}
                >
                  <FaShare color="green" className="me-2" />
                  5326 share
                </p>
                <p
                  className="ms-auto me-3"
                  onClick={handleShow3}
                  style={{ cursor: "pointer" }}
                >
                  <FaCommentAlt color="green" className="me-2" />
                  10 comments
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 news ">
            <img src={news2} width="100%" />
            <div className="desc my-4">
              <h5 className="fw-bold">On 21 may 2023</h5>
              <p>
                Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                interdum. Aliquam dolor eget urna ultricies tincidunt libero sit
                amet
              </p>
              <div
                className="w-100 d-flex p-1 views"
                style={{ backgroundColor: "blue" }}
              >
                <p
                  className="ms-3"
                  onClick={handleShow2}
                  style={{ cursor: "pointer" }}
                >
                  <FaShare color="green" className="me-2" />
                  1224 share
                </p>
                <p
                  className="ms-auto me-3"
                  onClick={handleShow3}
                  style={{ cursor: "pointer" }}
                >
                  <FaCommentAlt color="green" className="me-2" />6 comments
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 news ">
            <img src={news3} width="100%" />
            <div className="desc my-4">
              <h5 className="fw-bold">On 13 Dec 2023</h5>
              <p>
                Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                interdum. Aliquam dolor eget urna ultricies tincidunt libero sit
                amet
              </p>
              <div
                className="w-100 d-flex p-1 views"
                style={{ backgroundColor: "blue" }}
              >
                <p
                  className="ms-3"
                  onClick={handleShow2}
                  style={{ cursor: "pointer" }}
                >
                  <FaShare color="green" className="me-2" />
                  365 share
                </p>
                <p
                  className="ms-auto me-3"
                  onClick={handleShow3}
                  style={{ cursor: "pointer" }}
                >
                  <FaCommentAlt color="green" className="me-2" />9 comments
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: 'center',
                        justifyItems:'center',
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
        <div className="modal">
          <Modal show={show2} onHide={handleClose2} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={adjock} width={100} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-header text-center">
                  <h3 style={{ fontFamily: "cursive" }}>Share with </h3>
                </div>
                <div className="card-body text-center">
                  <Link>
                    <FaFacebook
                      size={30}
                      style={{ cursor: "pointer" }}
                      className="m-3"
                      color="blue"
                    />
                  </Link>
                  <Link>
                    <FaInstagram
                      size={30}
                      style={{ cursor: "pointer" }}
                      className="m-3"
                      color="#EF1E96"
                    />
                  </Link>
                  <Link>
                    <ImWhatsapp
                      size={30}
                      style={{ cursor: "pointer" }}
                      className="m-3"
                      color="#28EF1E"
                    />
                  </Link>
                  <Link>
                    <FaTwitter
                      size={30}
                      style={{ cursor: "pointer" }}
                      className="m-3"
                      color="#1ED6EF"
                    />
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="modal">
          <Modal show={show3} onHide={handleClose3} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={adjock} width={100} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-header text-center">
                  <h3 style={{ fontFamily: "cursive" }}>Comments</h3>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <div className="col-1">
                      <PiUserCircleLight size={24} />
                    </div>
                    <div className="col">
                      <span style={{ cursor: "pointer" }}>User-1</span>
                      <p>Its nice</p>
                    </div>
                  </div>
                  <div className="comment" style={{ marginTop: "200px" }}>
                    <div className="row">
                      <div className="col-1">
                        <PiUserCircleLight
                          size={24}
                          className="my-2"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="col d-flex">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please download mobile app"
                          style={{ border: "1px solid" }}
                          value={comment}
                          disabled
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <IoMdSend
                          className="ms-1 mt-1"
                          size={30}
                          style={{ cursor: "not-allowed", opacity: "0.5" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default News;
