import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import banner from "../Assets/Images/banner2.jpg";
import { Modal } from "react-bootstrap";
import app from "../Assets/Images/mobile.jpg";
import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import adjock from "../Assets/Images/Adjock.png";
import "./About.css";
import adbg from "../Assets/Images/adbg.png";
function About() {
  const [showLink, setShowLink] = useState(true);
  const [isTeam2, setIsTeam2] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setShowLink(!isScrolled);
    };
  }, []);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const handleShow1 = (e) => {
    setShow1(true);
  };

  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid">
      {showLink && (
        <div className="download-link" onClick={handleShow1}>
          <Link className="link">Download App</Link>
        </div>
      )}
      <Dashboard />
      <div className="">
        <img src={banner} width="100%" className="image" />
      </div>
      <div className="contents1 mx-2 mt-4">
        <div className="" style={{ textAlign: "justify" }}>
          <div className="row mx-3">
            <h3 className="fw-bold text-center my-4">About us</h3>
            <div className="col-md mx-1">
              <h2 className="fw-bold my-3">
                <Link to="/" className="ms-1">
                  <img src={adbg} width={120} />
                </Link>{" "}
                & It's Innovative Marketing Solutions
              </h2>
              <p className="my-3" style={{ textAlign: "justify" }}>
                We bridge the gap between products and consumers, empowering all
                businesses, influencers, and individuals to engage audiences
                directly around them. Unlike traditional digital ads, we
                prioritize proximity-based targeting, ensuring genuine
                connections which are adhar authenticated. ADJOCK is a
                hyper-local advertising Platform based on distance which
                connects all businesses, companies and customers in the
                neighborhood and provides monetary incentives,
                cashbacks,discounts etc for ad consumption.
              </p>
            </div>
            <div className="col-md">
              <h3 className="fw-bold">Vission</h3>
              <p>
                To collaborate with our society in realizing the strategic
                importance of advertising and to Provide Universal access to the
                connection between product and people to drive a new era of
                development, growth and productivity that promotes safe and a
                sound environment for the future generation.
              </p>
              <h3 className="fw-bold">Mission</h3>
              <p>
                Our mission is to seamlessly make every business part of
                people's daily lives by creating a personal, profitable, and
                universally accessible platform for advertising from villages to
                large cities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="team my-4">
        <div className="row">
          <div className="col-md-4 text-center team1">
            <h4 className="fw-bold">Team Details</h4>
            <h3 className="fw-bold" style={{ fontStyle: "italic" }}>
              Meet our expert Team
            </h3>
          </div>

          <div className="col-md me-3">
            <div className="row text-center py-4">
              <div
                className="col-md me-2 py-3 my-3"
                style={{
                  backgroundColor: "#F7F3EF",
                  borderRadius: "10px",
                  border: "10px groove #E7E1D9",
                }}
                onMouseEnter={() => setIsTeam2(false)}
                onMouseLeave={() => setIsTeam2(true)}
              >
                <div className="desc text-center py-3">
                  <h5 className="fw-bold">RAKESH KARIAPPA</h5>
                  <h6 className="fw-bold">Director</h6>
                  <p>
                    Director ,with a degree in Commerce holds Diploma in
                    Business Administration and Management. With over 13 years
                    of administrative and management experience in large
                    plantations, FMCG, and the pharmaceutical sector, I bring a
                    wealth of industry knowledge. Furthermore, Co-founded 2
                    successful businesses since 2019.
                  </p>
                </div>
              </div>
              <div
                className="col-md  py-3 my-3"
                style={{
                  backgroundColor: "#F7F3EF",
                  borderRadius: "10px",
                  border: "10px groove #E7E1D9",
                }}
                onMouseEnter={() => setIsTeam2(false)}
                onMouseLeave={() => setIsTeam2(true)}
              >
                <div className="desc text-center py-2">
                  <h5 className="fw-bold">DARSHAN JAMES</h5>
                  <h6 className="fw-bold">Director</h6>
                  <p>
                    Director, holds a Bachelor's degree in Information Science
                    and Support Services, along with a Diploma in Computer
                    Science. With 8 years of experience in the sales and
                    marketing domain, he brings valuable expertise to the table.
                    In 2019, he co-founded a company specializing in the IT and
                    BPO sectors, showcasing his entrepreneurial spirit and
                    industry insight.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: 'center',
                        justifyItems:'center',
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Footer />
    </div>
  );
}

export default About;
