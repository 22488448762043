import React, { useState, useEffect } from "react";
import contact from "../Assets/Images/download.jpg";
import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import app from "../Assets/Images/mobile.jpg";
import Button from "react-bootstrap/esm/Button";
import adjock from "../Assets/Images/Adjock.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { MdDownload } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import instruct from "../Assets/Videos/instruction.mp4";
import download3 from "../Assets/Images/download3.jpg";
import download4 from "../Assets/Images/download4.jpg";
import { FaShare } from "react-icons/fa6";
import "./Appstore.css";
import Dashboard from "./Dashboard";
import adbg from "../Assets/Images/adbg.png";
import Footer from "./Footer";

function AppStore() {
  const [showLink, setShowLink] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setShowLink(!isScrolled);
    };
  }, []);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const handleShow1 = (e) => {
    setShow1(true);
  };

  const notify = () => toast("Sent Successfully!");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const handleShow2 = (e) => {
    setShow2(true);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    setShow(true);
  };

  const [isShare, setIsShare] = useState(true);

  const handleShareClick = () => {
    setIsShare(false);
  };

  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid">
      {showLink && (
        <div className="download-link" onClick={handleShow2}>
          <Link className="link">Download App</Link>
        </div>
      )}
      <div className="banner6">
        <img src={contact} width="100%" className="image" />
        <div
          className="conten"
          style={{
            position: "absolute",
            top: "54%",
            left: "32%",
            transform: "translate(-50%,-50%)",
            textAlign: "center",
            padding: "30px",
            color: "white",
          }}
        >
          <div className="d-none d-sm-block">
            <h1 style={{ fontFamily: "fantasy", letterSpacing: "3px" }}>
              ADJOCK
            </h1>

            <div className="row mt-4 ">
              <div className="col android d-flex px-4 py-2 mx-4">
                <GrAndroid className="mt-2 me-2" size={28} />
                <a
                  className="mt-2"
                  href="adjock.apk"
                  download="adjock.apk"
                  style={{ textDecoration: "none" }} // Add this style to remove underline
                >
                  <h4
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Download App
                  </h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dashboard />
      <div className="contents6 pt-2">
        <div className="container text-center">
          <div className="row">
            <div className="col-md">
              <MdDownload size={40} color="red" className="my-3 setup" />
              <h3 className="fw-bold">EASY ACCESS</h3>
              <p>
                Download the through PlayStore/AppStore and sign up using phone
                number, fill all the mandatory fileds to create account and
                explore the app.
              </p>
            </div>
            <div className="col-md">
              <FaUsers size={40} color="red" className="my-3 social" />
              <h3 className="fw-bold">REFER & EARN</h3>
              <p>
                Refer friends and family members to download the ADJOCK app and
                earn money for every individual users.
              </p>
            </div>
            <div className="col-md">
              <LiaHandsHelpingSolid
                size={40}
                color="red"
                className="my-3 support"
              />
              <h3 className="fw-bold">24/7 SUPPORT</h3>
              <p>
                ADJOCK team will help you 24/7 for any user queries like
                Transactions,Security issues and Credential related problems and
                so on.
              </p>
            </div>
          </div>
          <div className="desc my-5">
            <div className="row">
              <div className="col-md-6 team1">
                <h3
                  className="my-3  fw-bold"
                  style={{
                    fontFamily: "",
                    letterSpacing: "2px",
                    fontStyle: "",
                  }}
                >
                  EMBARK{" "}
                  <Link to="/" className="ms-1">
                    <img src={adbg} width={110} />
                  </Link>
                </h3>
                <p>
                  "ADJOCK is an advertising Platform based on distance which
                  connects businesses, companies and customers to provides
                  incentives, cashbacks,discounts for ad consumption. ADJOCK is
                  an advertising Platform based on distance which connects
                  businesses, companies and customers to provides incentives,
                  cashbacks,discounts for ad consumption.ADJOCK is an
                  advertising Platform based on distance which connects
                  businesses, companies and customers to provides incentives,
                  cashbacks,discounts for ad consumption. "
                </p>
              </div>
              <div className="col">
                <img
                  src={download4}
                  width="85%"
                  style={{ borderRadius: "8px" }}
                />
              </div>
              <div className="col">
                <img
                  src={download3}
                  width="85%"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </div>
          <div className="my-5 col-md">
            <h3 className="fw-bold">
              {" "}
              <Link to="/" className="ms-1">
                <img src={adbg} width={110} />
              </Link>{" "}
              IN ACTION
            </h3>
            <video src={instruct} controls width="70%" className="my-3" />
            <h5 className="mt-2 mb-4 fw-bold">
              SHARE THIS VIDEO WITH YOUR FRIENDS
            </h5>
            {isShare ? (
              <span
                className="px-3 py-2 share text-center"
                onClick={handleShareClick}
              >
                <FaShare className="me-2" />
                Share
              </span>
            ) : (
              <div className="links text-center">
                <Link>
                  <FaFacebook className="icon" />
                </Link>
                <Link>
                  <FaInstagram className="icon" />
                </Link>
                <Link>
                  <FaLinkedinIn className="icon" />
                </Link>
                <Link>
                  <FaTwitter className="icon" />
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="modal">
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={adjock} width={100} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-header text-center">
                  <h3 style={{ color: "#1472EB" }}>Android app </h3>
                </div>
                <div className="card-body">
                  <p className="fw-bold">
                    Please enter your phone number below
                  </p>

                  <FloatingLabel
                    controlId="floatingPhone"
                    label="Phone Number"
                    className="mb-3"
                  >
                    <Form.Control type="text" placeholder="Phone Number" />
                  </FloatingLabel>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={notify} className="w-100">
                Get Link
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ color: "#1472EB" }}>iOS app </h3>
              </div>
              <div className="card-body">
                <p className="fw-bold">Please enter your phone number below</p>

                <FloatingLabel
                  controlId="floatingPhone"
                  label="Phone Number"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="Phone Number" />
                </FloatingLabel>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={notify} className="w-100">
              Get Link
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="modal">
        <Modal show={show2} onHide={handleClose2} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: "center",
                        justifyItems: "center",
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default AppStore;
