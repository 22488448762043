import React, { useState, useEffect } from 'react'
import Dashboard from './Dashboard'
import Footer from './Footer'
import Countup from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import ad1 from "../Assets/Images/ad1.png";
import ad2 from "../Assets/Images/ad2.jpg";
import step1 from "../Assets/Images/step1.jpg";
import step2 from "../Assets/Images/step2.png";
import step3 from "../Assets/Images/step3.png";
import { Modal } from "react-bootstrap";
import app from "../Assets/Images/mobile.jpg";
import banner1 from "../Assets/Images/banner1.jpg";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCol,
  MDBIcon,
  MDBTypography,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";

import collab from "../Assets/Images/collab.jpg";
import customer from "../Assets/Images/customer.jpg";
import cash from "../Assets/Images/cashback1.jpg";
import ad from "../Assets/Images/adbg.png";
import index from "../Assets/Images/ind2.jpg";
import play from "../Assets/Images/play.png";
import apple from "../Assets/Images/apple.png";

import { FaApple, FaTimes } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import adjock from "../Assets/Images/Adjock.png";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css"

function Home() {

  const [counterOn, setCounterOn] = useState(false);

  const [showLink, setShowLink] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setShowLink(!isScrolled);
    };
  }, []);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const handleShow1 = (e) => {
    setShow1(true);
  };

  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 
  return (
    <div className={`container-fluid app ${showLink ? "" : "hidden"}`}>
      {showLink && (
        <div className="download-link" onClick={handleShow1}>
          <Link className="link" download>
            Download App
          </Link>
        </div>
      )}
      <div className="slider ">
          <img
            className="d-block w-100 "
            src={banner1}
            alt="First slide"
            width="100%"
          />
          {/* <div
            className="ms-2 d-none d-sm-block"
            style={{
              position: "absolute",
              top: "30%",
              textAlign: "center",
              padding: "30px",
              letterSpacing: "3px",
              fontFamily: "fantasy",
            }}
          >
            <h4 style={{ color: "#2D4203" }}>Best in solutions</h4>
         
            <div class="col-sm-12">
              <div
                class="box animate fadeInLeft three fw-bold"
                style={{ fontSize: "65px" }}
              >
                An Innovative Advertising <br /> Company
              </div>
            </div>
          </div> */}
        </div>
   


      <Dashboard />
      <div className="webbody">
        <div className="p-1">
          <div className="row sloganbg">
            <div className="col-md-4 index p-4 ms-4 d-none d-sm-block">
              <img src={index} width="100%" height={450} />
            </div>
            <div className="col-md-7 mt-5">
              <div className="slogan ">
                <h3 className="text-center fw-bold ">
                  <img src={ad} width={100} alt="no" /> optimizes client value
                  by providing the futuristic programatic <br /> solutions
                  across the entire advertising spectrum
                </h3>
              </div>
              <div className="container d-flex flex-column align-items-center justify-content-center">
                <h1
                  className="text-center fw-bold"
                  style={{ marginTop: "30px", fontStyle: "oblique" }}
                >
                  Fantastic deals at over a million stores around you!
                </h1>

                <h5
                  className="text-center fw-bold"
                  style={{ marginTop: "30px" }}
                >
                  Get the app download link via SMS and start using our app
                  today. Ready to get started ?
                </h5>
                <div className="col-md-7 mt-4">
                  <form
                    className="d-flex"
                    style={{
                      width: "100%",
                    }}
                  >
                    <input
                      class="form-control me-2 w-100"
                      type="search"
                      placeholder="Enter Mobile Number"
                      aria-label="Search"
                      style={{
                        width: "100%",
                        border: "1px solid",
                      }}
                    />
                    <button class="btn btn-danger">Send</button>
                  </form>
                  <div className="d-flex mt-4 align-items-center">
                    <img
                      className="mb-2"
                      src={play}
                      width={200}
                      height={56}
                      style={{ cursor: "pointer", borderRadius: "10px" }}
                    />
                    <img
                      className="ms-2 mb-2"
                      src={apple}
                      width={200}
                      height={56}
                      style={{ cursor: "pointer", borderRadius: "10px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-md-9 container mt-5">
              <h1 className="text-center fw-bold">
                How does <img src={ad} width={130} /> work's ?
              </h1>
            </div>
          </div>
          <div className="container   mt-4">
            <div className="row ">
              <div className="col-md-5">
                <h2 className="text-center fw-bold">
                  Earn <img src={ad} width={130} /> Money
                </h2>
                <div className="col mt-5">
                  <div className="row">
                    <div className="col-md text-center">
                      <img
                        src={ad1}
                        width={200}
                        height={250}
                        style={{ borderRadius: "12px" }}
                      />
                      <h5 className="my-3 fw-bold">Watch Ads</h5>
                      <p>Watch our business ads whenever you are free</p>
                    </div>
                    <div className="col text-center">
                      <img
                        src={ad2}
                        width={200}
                        height={250}
                        style={{ borderRadius: "12px" }}
                      />
                      <h5 className="my-3 fw-bold">Earn money</h5>
                      <p>You'll get money after completing the ads</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <h2 className="text-center fw-bold">
                  Save huge using <img src={ad} width={130} />
                </h2>
                <div className="col mt-5">
                  <div className="row mt-5">
                    <div className="col-md text-center">
                      <img src={step1} width={200} height={100} />
                      <div className="my-5">
                        <h5 className="fw-bold">Step-1</h5>
                        <p>
                          Download our app and get register with valid details
                        </p>
                      </div>
                    </div>
                    <div className="col-md text-center">
                      <img src={step2} width={200} height={130} />
                      <div className="my-3">
                        <h5 className="fw-bold">Step-2</h5>
                        <p>Check for near by business/shops around you</p>
                      </div>
                    </div>
                    <div className="col-md text-center">
                      <img src={step3} width={200} height={130} />
                      <div className="my-3">
                        <h5 className="fw-bold">Step-3</h5>
                        <p>
                          You'll get money after billing through{" "}
                          <span className="fw-bold">ADJOCK</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 count container">
          <h3
            className="text-center py-2 fw-bold"
            style={{
              fontFamily: "fantasy",
              letterSpacing: "3px",
              fontStyle: "oblique",
            }}
          >
            India's Best Rewards & Coupons Site
          </h3>

          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div className="row  my-5">
              <div className="col-md-4">
                <div className="image-container p-1">
                  <img src={collab} width="100%" height={270} />
                  <div className="image-desc mt-2">
                    <h4 className="fw-bold text-center">
                      Coact with
                      {counterOn && (
                        <Countup
                          className="ms-2"
                          start={0}
                          end={123}
                          duration={4}
                          delay={0}
                        />
                      )}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image-container p-1">
                  <img src={customer} width="100%" height={270} />
                  <div className="image-desc mt-2">
                    <h4 className="fw-bold text-center">
                      Happy Customers
                      {counterOn && (
                        <Countup
                          className="ms-2"
                          start={0}
                          end={10}
                          duration={4}
                          delay={0}
                        />
                      )}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image-container p-1">
                  <img src={cash} width="100%" height={270} />
                  <div className="image-desc mt-2">
                    <h4 className="fw-bold text-center">
                      Paid Rewards
                      {counterOn && (
                        <Countup
                          className="ms-2"
                          start={0}
                          end={1000}
                          duration={4}
                          delay={0}
                        />
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </ScrollTrigger>
        </div>

        <div className="">
          <h4 className="text-center fw-bold">Our Testimonial </h4>
          <h1
            className="text-center my-3 fw-bold"
            style={{ fontFamily: "fantasy", fontStyle: "oblique" }}
          >
            Our Client Saying!
          </h1>
          <MDBContainer className="py-5">
            <MDBCarousel showControls dark>
              <MDBCarouselItem className="active">
                <MDBContainer>
                  <MDBRow className="text-center">
                    <MDBCol lg="4" className="mb-5 mb-md-0">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-1</h5>
                      <h6 className="text-primary mb-3">UX Designer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quod eos id officiis hic tenetur quae quaerat ad
                        velit ab hic tenetur.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol lg="4" className="d-none d-lg-block">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-2</h5>
                      <h6 className="text-primary mb-3">Web Developer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        Ut enim ad minima veniam, quis nostrum exercitationem
                        ullam corporis suscipit laboriosam, nisi ut aliquid
                        commodi.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star-half-alt"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol lg="4" className="d-none d-lg-block">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-3</h5>
                      <h6 className="text-primary mb-3">Photographer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            far
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCarouselItem>
              <MDBCarouselItem>
                <MDBContainer>
                  <MDBRow className="text-center">
                    <MDBCol lg="4" className="mb-5 mb-md-0">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-4</h5>
                      <h6 className="text-primary mb-3">UX Designer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quod eos id officiis hic tenetur quae quaerat ad
                        velit ab hic tenetur.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol lg="4" className="d-none d-lg-block">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(4).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-5</h5>
                      <h6 className="text-primary mb-3">Web Developer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        Ut enim ad minima veniam, quis nostrum exercitationem
                        ullam corporis suscipit laboriosam, nisi ut aliquid
                        commodi.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star-half-alt"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol lg="4" className="d-none d-lg-block">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(5).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-6</h5>
                      <h6 className="text-primary mb-3">Photographer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            far
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCarouselItem>
              <MDBCarouselItem>
                <MDBContainer>
                  <MDBRow className="text-center">
                    <MDBCol lg="4" className="mb-5 mb-md-0">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-7</h5>
                      <h6 className="text-primary mb-3">UX Designer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quod eos id officiis hic tenetur quae quaerat ad
                        velit ab hic tenetur.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol lg="4" className="d-none d-lg-block">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(8).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-8</h5>
                      <h6 className="text-primary mb-3">Web Developer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        Ut enim ad minima veniam, quis nostrum exercitationem
                        ullam corporis suscipit laboriosam, nisi ut aliquid
                        commodi.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star-half-alt"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol lg="4" className="d-none d-lg-block">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(7).webp"
                          className="rounded-circle shadow-1-strong"
                          width="150"
                          height="150"
                        />
                      </div>
                      <h5 className="mb-3 fw-bold">Client-9</h5>
                      <h6 className="text-primary mb-3">Photographer</h6>
                      <p className="px-xl-3">
                        <MDBIcon fas icon="quote-left" className="pe-2" />
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti.
                      </p>
                      <MDBTypography
                        listUnStyled
                        className="d-flex justify-content-center mb-0"
                      >
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            fas
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                        <li>
                          <MDBIcon
                            far
                            icon="star"
                            size="sm"
                            className="text-warning"
                          />
                        </li>
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCarouselItem>
            </MDBCarousel>
          </MDBContainer>
        </div>
      </div>
      <div className="modal">
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={adjock} width={100} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-header text-center">
                <h3 style={{ fontFamily: "cursive" }}>Download the app </h3>
              </div>
              <div className="card-body">
                <div className="row text-center">
                  <div className="col">
                    <a
                      className="d-inline-block mt-2 btn btn-primary btn-lg"
                      href="adjock.apk"
                      download="adjock.apk"
                      style={{
                        textDecoration: "none",
                        alignItems: 'center',
                        justifyItems:'center',
                        color: "#333",
                        minWidth: "200px", // Adjust as needed
                        borderRadius: "5px", // Add rounded corners
                        backgroundColor: "#fff8cc", // Set background color
                      }}
                    >
                      <GrAndroid className="mt-1 me-2" size={28} />
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Android
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </div>
  )
}

export default Home