import React from 'react'
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import adbg from "../Assets/Images/adbg.png";

function Footer() {
    return (
        <div className='container-fluid'>
            <div className="footer my-2 p-5" style={{ backgroundColor: "black", color: "white" }}>
                <div className="row  ">
                    <div className="col-md-3">
                        <Link to="/">
                        <img src={adbg} width={130} alt="Adjock Logo" />
                        </Link>
                        
                        <p className="mt-4 text-white">
                            "ADJOCK is an advertising Platform based on distance which
                            connects businesses, companies and customers to provides
                            incentives, cashbacks,discounts for ad consumption."
                        </p>
                    </div>
                    <div className="col-md-3">
                        <h4 style={{ color: "#1E62EA" }}>Short Link</h4>
                        <ul className="list-unstyled mt-4">
                            <li className="my-2">
                                <Link to="/About" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> About Us
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link to="/News" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> News Room
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link to="/Career" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> Career
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link to="/Services" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> Service
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link to="/Contact" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4 style={{ color: "#1E62EA" }}>Help Link</h4>
                        <ul className="list-unstyled mt-4">
                            <li className="my-2">
                                <Link to="/Terms" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> Terms & Conditions
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link to="/Privacy" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> Privacy Policy
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link to="/Faq" className="text-white text-decoration-none">
                                    <IoMdArrowDropright className="me-2" style={{ color: "#1E62EA" }} /> FAQ's
                                </Link>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="col-md-3 col-sm-6  mt-4">
                        <h4 style={{ color: "#1E62EA" }}>Reach Us</h4>
                        <ul className="list-unstyled mt-4">
                            <li className="my-2" style={{ cursor: "pointer" }}>
                                <FaLocationDot
                                    className="me-2"
                                    size={20}
                                    style={{ color: "#1E62EA" }}
                                />{" "}
                                3<sup>rd</sup> floor CK Complex , Vijayanagar 3<sup>rd</sup>{" "}
                                Stage , Mysuru Karnataka 570017
                            </li>
                            <li className="my-2" style={{ cursor: "pointer" }}>
                                <FaPhone
                                    className="me-2"
                                    size={20}
                                    style={{ color: "#1E62EA" }}
                                />{" "}
                                +91 8762121937
                            </li>
                            <li className="my-2" style={{ cursor: "pointer" }}>
                                <IoMail
                                    className="me-2"
                                    size={20}
                                    style={{ color: "#1E62EA" }}
                                />{" "}
                                info@adjock.com
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="d-flex mt-4">
                    <div className="col-md-6">
                        <h4 style={{ color: "#1E62EA" }}>Follow us on:</h4>
                        <Link className="text-white text-decoration-none mb-3 ">
                            <FaFacebook size={30} className="me-3 mb-3" />
                        </Link>
                        <Link className="text-white text-decoration-none">
                            <FaInstagram size={30} className="me-3 mb-3" />
                        </Link>
                        <Link className="text-white text-decoration-none">
                            <FaTwitter size={30} className="me-3 mb-3" />
                        </Link>
                        <Link className="text-white text-decoration-none">
                            <FaLinkedinIn size={30} className="me-3 mb-3 " />
                        </Link>
                    </div>
                    <div className="col-md-6 ms-auto">
                        <p className="text-white mt-3">Copyright © 2024 TARK INNOVATIONS PRIVATE LIMITED. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
