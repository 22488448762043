import React,{useEffect} from 'react'
import Table from "react-bootstrap/Table";
import Dashboard from './Dashboard';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";
function Privacy() {
    const history = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='container-fluid'>
        <Dashboard/>
           <div
        className="container col-md-8 p-5  mt-5"
        style={{ textAlign: "justify" }}
      >
        <h2 className="text-center" style={{color:"#1E62EA",fontWeight:'bold'}}>Privacy Policy</h2>
        <h5 className="mt-5 fw-bold">BOARD MEMBERS AND SENIOR MANAGEMENT</h5>
        <p className="my-2">
          The adoption of this Privacy Policy (the Code) by the Board stems from
          the fiduciary responsibility which the Board Members and the Senior
          Management have towards the stakeholders in the Company. Therefore,
          Board Members and Senior Management act as trustees in the interest of
          all stakeholders of the Company, by balancing conflicting interest, if
          any, between stakeholders, for optimal benefit. (In the observance of
          the Code, the Directors in their capacity as Directors, per se, have a
          Directional role and the Executive Directors and Senior Management
          Personnel have executive responsibility.)
        </p>
        <p>The Policy encompasses the following:</p>
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th className="text-center fw-bold">POLICIES</th>
              <th className="text-center fw-bold">DESCRIPTION</th>
            </tr>
            <tr>
              <th style={{ verticalAlign: "middle" }} className="fw-bold">
                1. ETHICAL AND MORAL BEHAVIOUR
              </th>
              <td>
                <li>
                  Business shall be conducted ethically and by not committing,
                  condoning or ratifying illegal or immoral acts.
                </li>

                <li>
                  Standards of fair dealing and personal integrity will be
                  observed.
                </li>
                <li>
                  An environment free from gender-specific harassment,
                  discrimination and exploitation will be maintained.
                </li>
              </td>
            </tr>
            <tr>
              <th className="fw-bold" style={{ verticalAlign: "middle" }}>
                2. CONDUCT OF BUSINESS
              </th>
              <td>
                Conduct of the business will be consistent with the Core Values,
                which are as under:-
                <li>Customer Sovereignty</li>
                <li>People Orientation.</li>
                <li>Innovation and Entrepreneurship</li>
                <li>Transparency and Integrity</li>
                <li>Anticipation, speed and flexibility</li>
                <li>Passion for superior performance</li>
              </td>
            </tr>
            <tr>
              <th className="fw-bold">3. SAFETY, HEALTH AND CLEAN ENVIRONMENT</th>
              <td>
                In all aspects of Company's operations, its employees, customers
                and society, safe, healthy and clean environment shall be given
                prime importance.
              </td>
            </tr>
            <tr>
              <th className="fw-bold" style={{ verticalAlign: "middle" }}>
                4. CONFLICT OF INTEREST
              </th>
              <td>
                <li>
                  Conflict Situations: Any relationship, influence, or activity
                  that may impair the ability to make objective and fair
                  decisions is to be avoided.
                </li>
                <li>
                  Disclosure of Interest: Any financial or other material
                  interest by oneself or through relatives / organization in any
                  contract with the company (including vendors and customers),
                  shall be disclosed at first opportunity.
                </li>
                <li>
                  Competing with the Company: Acceptance of any position of
                  influence or of pecuniary interest in any other organisation,
                  whose business is in direct competition with that of the
                  Company, shall be avoided.
                </li>
                <li>
                  Use of Company's Resources / assets / property: Assets of the
                  company are to be used solely for the benefit of the company.
                  Opportunities discovered through use of corporate property,
                  information or position shall not be exploited, for own
                  personal gain, unless the opportunity is disclosed fully in
                  writing to the Company.
                </li>
              </td>
            </tr>
            <tr>
              <th className="fw-bold" style={{ verticalAlign: "middle" }}>
                5.CONFIDENTIALITY OF INFORMATION
              </th>
              <td>
                Any information (electronic or otherwise) relating to the
                Company, its intellectual property including technology and
                business processes, its business and customers / vendors which
                is confidential, shall not be divulged.
              </td>
            </tr>
            <tr>
              <th className="fw-bold" style={{ verticalAlign: "middle" }}>
                6.COMPLIANCE WITH LAWS, RULES AND REGULATIONS
              </th>
              <td>
                All laws, rules and regulations are to be complied with. The
                compliance and reporting systems established should be adhered
                to.
              </td>
            </tr>
            <tr>
              <th className="fw-bold">
                7.COMPLIANCE WITH THE DUTIES PRESCRIBED UNDER THE COMPANIES ACT,
                2013 (APPLICABLE TO INDEPENDENT DIRECTORS ONLY)
              </th>
              <td style={{ verticalAlign: "middle" }}>
                The Independent Directors of the Company, in addition to the
                aforesaid duties, shall also ensure compliance with the duties
                as prescribed under Schedule IV of the Companies Act, 2013.
              </td>
            </tr>
          </thead>
        </Table>
      </div>
      <Footer/>
    </div>
  )
}

export default Privacy